// Config
import { CollectionSlug } from '../config/collections';
import { demoWallet } from '../config/config';
import { CollectionDetails } from "../config/collections.interface";

// Ionic
import { Storage } from '@ionic/storage';

// Interfaces
import User from '../interfaces/User.interface';
import { EtherscanResponse, TokenResult } from '../interfaces/Etherscan.interface';

// Colyseus
import { TokenMetadata } from '../generated/TokenMetadata';

// Services
import { reserviorGetOwners } from './Reservior';

// Prepare local storage
const storage = new Storage();
storage.create();

/**
 * Set Wallet Address and Balance
 * @param user User object
 * @param signature boolean
 * @param demo boolean
 */
export const getAccountsAndSign = async (user: User, signature: boolean = true, demo?: boolean) => {
    return new Promise<User>(async (resolve, reject) => {

        if (demo) {
            user.wallet = demoWallet;
            user.ethBalance = 0;
            resolve(user);
        } else {

            try {

                await user.web3.eth.getAccounts().then(async (accounts: string[]) => {

                    if (accounts && accounts[0]) {

                        // Assign wallet address
                        const wallet = accounts[0];
                        user.wallet = wallet;
                        user.web3.eth.defaultAccount = wallet;

                        // Request signature
                        if (signature) {
                            console.log("Requesting signature...");
                            try {
                                const message = "Please sign this message to confirm your wallet address.";
                                const signature = await user.web3.eth.personal.sign(message, wallet, "");
                                console.log("Signature obtained:", signature);
                                resolve(user);
                            } catch (signError) {
                                reject(signError);
                            }
                        } else {
                            console.log("No signature requested.");
                            resolve(user);
                        }

                    }

                }).catch((error: any) => {
                    reject(error);
                });

            } catch (error: any) {
                reject(error);
            }

        }

    });
}

/**
 * Check stored provider preference
 */
export const checkStoredWallet = async (user: User) => {
    if (!user.wallet) {
        return new Promise<string>((resolve) => {
            storage.get('provider').then((provider: string) => {
                if (provider) {
                    resolve(provider);
                }
            });
        });
    }
}

/**
 * Set and Emit wallet
 * @param wallet string
 * @param user User object
 */
export const setAndEmitWallet = async (user: User, wallet: string) => {
    user.wallet = wallet;
    return new Promise<User>((resolve) => {
        resolve(user);
    });
}

/**
 * Get Contract
 * @param contractAbi any
 * @param contractAddresses ContractNetworks
 * @returns Contract
 */
// export const getContract = async (contractAbi: any, contractAddresses: ContractNetworks) => {
//     const contractAddress = mode === 'p' ? contractAddresses[1] : contractAddresses[5];
//     return new infura.eth.Contract(contractAbi, contractAddresses[mode === 'p' ? 1 : 5])
// },

/**
 * Get Balance
 * @param user User object
 * @param contract Contract
 * @returns number
 */
export const getBalance = async (user: User, contract: any): Promise<number> => {
    const owned: string = await contract.methods.balanceOf(user.wallet).call();
    return user.web3.utils.toNumber(owned);
}

/**
 * Delay API
 * @param ms number
 * @returns Promise
 */
export const promiseDelay = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Get Collectible Count
 * @param user User object
 * @param collections CollectionDetails[]
 * @param demo boolean
 * @returns number
 */
export const getCollectibleCount = async (user: User, collections: CollectionDetails[]): Promise<TokenResult[]> => {
    const collectibles: TokenResult[] = [];
    for (const collection of collections) {
        if (collection.enabled) {
            const tokensResponse = await fetchTokens(user, collection);
            if (tokensResponse && tokensResponse.status === "1" && tokensResponse.result) {
                tokensResponse.result.forEach(token => {
                    collectibles.push(token)
                });
            }
            await promiseDelay(1);
        }
    }
    return collectibles;
}

/**
 * Fetch Token Metadata
 * @param collectibles TokenMetadata[]
 * @param collection CollectionDetails
 * @param tokenID number
 * @returns boolean
 */
export const fetchTokenMetadata = async (collection: CollectionDetails, tokenID: number): Promise<TokenMetadata | undefined> => {
    let metadata;

    try {
        await promiseDelay(1);

        if (collection.local) {
            const importedMetadata = await import(`./../collections/${collection.slug}/metadata/${tokenID}.json`);
            metadata = importedMetadata.default;
        } else {

            // Determine if mainnet or testnet should be used
            const isMainnet = collection?.mainnet_baseuri && collection?.mainnet_baseuri !== '';
            const isTestnet = !isMainnet && collection?.testnet_baseuri && collection?.testnet_baseuri !== '';

            if (!isMainnet && !isTestnet) {
                throw new Error('No valid network base URI found for the collection');
            }

            // Construct the metadata fetching path based on the network
            const baseURI = isMainnet ? collection.mainnet_baseuri : collection.testnet_baseuri;
            const extension = isMainnet ? collection.mainnet_extension : collection.testnet_extension;
            const path = `${baseURI}${tokenID}${extension || ''}`;

            const response = await fetch(path);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            metadata = await response.json();
        }

        return { ...metadata, token_id: tokenID, collection: collection.slug };

    } catch (err) {
        console.error(err);
    }
}

/**
 * Fetch Tokens
 * @param user User object
 * @param collection CollectionDetails
 * @param demo boolean
 * @returns EtherscanResponse
 */
export const fetchTokens = async (user: User, collection: CollectionDetails): Promise<EtherscanResponse> => {
    try {

        let response: EtherscanResponse = {
            status: "1",
            message: "OK",
            result: []
        }

        let count = 0;

        await reserviorGetOwners(user, collection, 200).then(async (res: any[]) => {
            res.forEach((token: { tokenId: number, rarityRank: number }) => {
                count++;
                response.result.push({
                    "TokenAddress": `${user.wallet}`,
                    "TokenId": `${token.tokenId}`,
                    "RarityRank": `${token.rarityRank}`,
                    "Collection": collection
                })
                if ((count === res.length)) {
                    return response;
                }
            });
        }).catch((err) => {
            console.error('reserviorGetOwners for collection', collection.name, err);
        });

        return response;

    } catch (err) {
        console.error(err);
        throw err;
    }
}

/**
 * Fetch Token Metadata
 * @param tokenID number
 * @param collections CollectionDetails[]
 * @param collectionName string
 * @returns TokenMetadata
 */
export const fetchTokenByIDandCollectionName = async (tokenID: number, collections: CollectionDetails[], collectionName: CollectionSlug) => {
    return new Promise<TokenMetadata>(async (resolve, reject) => {
        try {

            let metadata = new TokenMetadata();
            const collection = collections.find((c) => c.slug === collectionName);
            if (collection?.local) {
                const importedMetadata = await import(`./../collections/${collection.slug}/metadata/${tokenID}.json`);
                metadata = importedMetadata.default;
            } else {

                console.log('Fetching metadata for token', tokenID, 'in collection', collectionName);

                // Determine if mainnet or testnet should be used
                const isMainnet = collection?.mainnet_baseuri && collection?.mainnet_baseuri !== '';
                const isTestnet = !isMainnet && collection?.testnet_baseuri && collection?.testnet_baseuri !== '';

                if (!isMainnet && !isTestnet) {
                    console.warn('No valid network base URI found for the collection', collection);
                    throw new Error('No valid network base URI found for the collection');
                }

                // Construct the metadata fetching path based on the network
                const baseURI = isMainnet ? collection.mainnet_baseuri : collection.testnet_baseuri;
                const extension = isMainnet ? collection.mainnet_extension : collection.testnet_extension;
                const path = `${baseURI}${tokenID}${extension || ''}`;

                const response = await fetch(path);

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                metadata = await response.json();

            }

            metadata.token_id = tokenID;
            metadata.collection = collectionName;
            resolve(metadata);

        } catch (error) {
            reject(error);
        }
    });
}
